import React from 'react';
import './Downloads.css';

function Downloads() {
  return (
    <section className="downloads">
      <div className="downloads-box">
        <h2>Téléchargements</h2>
        <p>Téléchargez les fichiers nécessaires pour rejoindre notre serveur.</p>
        <a href="https://drive.proton.me/urls/9YA89NY4B4#InyQI8wE4dMC" target="_blank" rel="noopener noreferrer" className="download-link">
          Télécharger le client WoW
        </a>
      </div>
    </section>
  );
}

export default Downloads;