import React from 'react';
import HomePage from '../components/home/HomePage';
import './Home.css';

function Home() {
  return (
    <main>
      <HomePage />
    </main>
  );
}

export default Home;
