import React from 'react';
import './Discord.css';

function Discord() {
  return (
    <section className="discord-section">
      <div className="discord-box">
        <h2>Rejoignez notre communauté sur Discord !</h2>
        <p>Discutez avec d'autres joueurs et restez informé des dernières nouvelles.</p>
        <a href="https://discord.gg/zqqp4sFgfQ" target="_blank" rel="noopener noreferrer" className="discord-link">
          Rejoindre le Discord
        </a>
      </div>
    </section>
  );
}

export default Discord;