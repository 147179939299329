import React from 'react';
import './ServerInfo.css';

function ServerInfo() {
  return (
    <section className="server-info">
      <div className="info-box">
        <h2>Informations sur le Serveur</h2>
        <p>
          Bienvenue sur notre serveur WoW hébergé par <strong className="highlight">Zaptoinc</strong> ! Voici quelques informations importantes :
        </p>
        <ul>
          <li><strong>Version du serveur :</strong> 3.3.5a</li>
          <li><strong>IP du realm :</strong> olinux.fr</li>
          <li><strong>Expérience :</strong> x3</li>
          <li><strong>Top de drop :</strong> élevé</li>
          <li><strong>Création de guilde :</strong> 3 personnes pour signer</li>
        </ul>
        <p>
          Rejoignez-nous pour une aventure épique avec des taux d'expérience et de drop améliorés, et une communauté accueillante prête à vous aider à créer votre propre guilde !
        </p>
      </div>
    </section>
  );
}

export default ServerInfo;