// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/navbar/Navbar';
import HomePage from './pages/Home';
import ServerPage from './pages/Server';
import DiscordPage from './pages/Discord';
import DownloadsPage from './pages/Downloads';
import SignupPage from './pages/Signup'; // Assurez-vous que le chemin est correct
import ContactPage from './pages/Contact';
import NotFound from './pages/NotFound'; // Vérifiez ce chemin

const routes = [
  { path: '/', element: <HomePage />, exact: true },
  { path: '/serveur', element: <ServerPage /> },
  { path: '/discord', element: <DiscordPage /> },
  { path: '/telechargements', element: <DownloadsPage /> },
  { path: '/inscription', element: <SignupPage /> }, // Ajouter la route pour l'inscription
  { path: '/contact', element: <ContactPage /> },
  { path: '*', element: <NotFound /> }
];

const App = () => {
  return (
    <Router>
      <Navbar />
      <Routes>
        {routes.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            element={route.element}
            exact={route.exact} // React Router v6 n'a pas besoin de "exact"
          />
        ))}
      </Routes>
    </Router>
  );
};

export default App;