import React from 'react';
import Discord from '../components/discord/Discord';
import './Discord.css';

function DiscordPage() {
  return (
    <main>
      <Discord />
    </main>
  );
}

export default DiscordPage;
