// src/pages/Signup.js
import React from 'react';
import './Signup.css';

const SignupPage = () => {
  const handleSubmit = (e) => {
    e.preventDefault();
    sendForm();
  };

  const sendForm = () => {
    const username = document.querySelector('input[placeholder="Nom d\'utilisateur"]').value;
    const email = document.querySelector('input[placeholder="Email"]').value;
    const password = document.querySelector('input[placeholder="Mot de passe"]').value;

    const subject = encodeURIComponent('New Signup');
    const body = encodeURIComponent(`Username: ${username}\nEmail: ${email}\nPassword: ${password}`);
    const mailtoLink = `mailto:olivier@olinux.fr?subject=${subject}&body=${body}`;

    window.location.href = mailtoLink;
  };

  return (
    <div className="signup">
      <div className="signup-box">
        <h2>Inscription</h2>
        <form className="signup-form" onSubmit={handleSubmit}>
          <input type="text" placeholder="Nom d'utilisateur" required />
          <input type="email" placeholder="Email" required />
          <input type="password" placeholder="Mot de passe" required />
          <button type="submit">S'inscrire</button>
        </form>
      </div>
    </div>
  );
};

export default SignupPage;