// src/components/Navbar.js
import React from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css'; 

const Navbar = () => {
  return (
    <nav className="navbar">
      <ul className="navbar-list">
        <li className="navbar-item">
          <Link to="/" className="navbar-link">Accueil</Link>
        </li>
        <li className="navbar-item">
          <Link to="/serveur" className="navbar-link">Serveur</Link>
        </li>
        <li className="navbar-item">
          <Link to="/discord" className="navbar-link">Discord</Link>
        </li>
        <li className="navbar-item">
          <Link to="/telechargements" className="navbar-link">Téléchargements</Link>
        </li>
        <li className="navbar-item">
          <Link to="/inscription" className="navbar-link">Inscription</Link>
        </li>
        <li className="navbar-item">
          <Link to="/contact" className="navbar-link">Contact</Link>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;