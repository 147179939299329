import React from 'react';
import './HomePage.css';

function HomePage() {
  return (
    <section className="home-page">
      <div className="presentation-box">
        <h1>Bienvenue sur notre serveur privé World of Warcraft !</h1>
        <p>Plongez dans une aventure épique avec notre serveur WoW privé. Restez à jour avec les dernières nouvelles, événements et fonctionnalités exclusives que nous avons à offrir.</p>
        <p>Rejoignez notre communauté passionnée et vivez une expérience de jeu inoubliable. Que vous soyez un vétéran ou un nouveau joueur, il y a toujours quelque chose de nouveau à découvrir.</p>
      </div>
    </section>
  );
}

export default HomePage;