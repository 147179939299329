import React from 'react';
import Contact from '../components/contacte/Contact';
import './Contact.css';

function ContactPage() {
  return (
    <main>
      <Contact />
    </main>
  );
}

export default ContactPage;
