import React from 'react';
import Downloads from '../components/download/Downloads';
import './Downloads.css';

function DownloadsPage() {
  return (
    <main>
      <Downloads />
    </main>
  );
}

export default DownloadsPage;
