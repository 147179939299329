import React from 'react';
import ServerInfo from '../components/serverinfo/ServerInfo';
import './Server.css';

function Server() {
  return (
    <main>
      <ServerInfo />
    </main>
  );
}

export default Server;
